import React, { useEffect, useState } from "react";
import "./armonizacionContable.css";
// Import Card component
import Card from "../Card/Card";

const articles = [
  {
    title: "42 LPEEH 2019",
    link: "/CAASIM/Armonizacion/_Calendario Anual Autorizado/Artículo_42_LPEEH_2019.pdf",
    year: 2019,
  },
  {
    title: "41 LPEEH 2020",
    link: "/CAASIM/Armonizacion/_Calendario Anual Autorizado/Artículo_41_LPEEH_2020.pdf",
    year: 2020,
  },
  {
    title: "39 LPEEH 2021",
    link: "/CAASIM/Armonizacion/_Calendario Anual Autorizado/Artículo_39_LPEEH_2021.pdf",
    year: 2021,
  },
  {
    title: "39 LPEEH 2022",
    link: "/CAASIM/Armonizacion/_Calendario Anual Autorizado/Artículo_39_LPEEH_2022.pdf",
    year: 2022,
  },
  {
    title: "39 LPEEH 2023",
    link: "/CAASIM/Armonizacion/_Calendario Anual Autorizado/Artículo_39_LPEEH_2023.pdf",
    year: 2023,
  },
  {
    title: "39 LPEEH 2024",
    link: "/CAASIM/Armonizacion/_Calendario Anual Autorizado/Artículo_39_LPEEH_2024.pdf",
    year: 2024,
  },
];

function ArmonizacionContable() {
  return (
    <>
      <div className="container py-4">
        <div className="secretaria_funciones">
          <h3 className="title">Armonización Contable</h3>
          <hr className="hr-gob" />
        </div>
        <div className="container m-auto">
          <MyTable />
        </div>
        <div className="secretaria_funciones">
          <h3 className="title">Calendario Anual Autorizado</h3>
          <hr className="hr-gob" />
        </div>
        <div className="row">
          {articles.map((article, i) => (
            <Card
              key={i}
              document={"Artículo"}
              title={article.title}
              href={article.link}
              type="PDF"
              year={article.year}
            />
          ))}
        </div>
      </div>
    </>
  );
}

function MyTable() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [data, setData] = useState([
    ["Año", "2025", "2024", "2023", "2022", "2021", "2020", "2019", "2018"],
    [
      "Información Trimestral",
      {
        text: "PDF",
        // link: "/",
      },
      {
        text: "PDF",
        link: "/CAASIM/armonizacion/trimestral/2024/trimestral 2024.pdf",
      },
      {
        text: "PDF",
        link: "/CAASIM/armonizacion/trimestral/2023/trimestral 2023.pdf",
      },
      {
        text: "PDF",
        link: "/CAASIM/armonizacion/trimestral/2022/trimestral 2022.pdf",
      },
      {
        text: "PDF",
        link: "/CAASIM/armonizacion/trimestral/2021/trimestral 2021.pdf",
      },
      {
        text: "PDF",
        link: "/CAASIM/armonizacion/trimestral/2020/trimestral 2020.pdf",
      },
      {
        text: "PDF",
        link: "/CAASIM/armonizacion/trimestral/2019/trimestral 2019.pdf",
      },
      {
        text: "PDF",
        link: "/CAASIM/armonizacion/trimestral/2018/trimestral 2018.pdf",
      },
    ],
    [
      "Información Anual",
      { text: "PDF", link: "/CAASIM/armonizacion/anual/2025/anual 2025.pdf" },
      { text: "PDF", link: "/CAASIM/armonizacion/anual/2024/anual 2024.pdf" },
      { text: "PDF", link: "/CAASIM/armonizacion/anual/2023/anual 2023.pdf" },
      { text: "PDF", link: "/CAASIM/armonizacion/anual/2022/anual 2022.pdf" },
      { text: "PDF", link: "/CAASIM/armonizacion/anual/2021/anual 2021.pdf" },
      { text: "PDF", link: "/CAASIM/armonizacion/anual/2020/anual 2020.pdf" },
      { text: "PDF", link: "/CAASIM/armonizacion/anual/2019/anual 2019.pdf" },
      { text: "PDF", link: "/CAASIM/armonizacion/anual/2018/anual 2018.pdf" },
    ],
  ]);

  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth < 768);

    // Call listener once to detect initial window size
    checkMobile();

    // Set event listener for window resizing
    window.addEventListener("resize", checkMobile);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", checkMobile);
  }, []); // Empty dependency array ensures this runs on mount and unmount only

  useEffect(() => {
    if (isMobile) {
      const transpose = (matrix) =>
        matrix[0].map((_, i) => matrix.map((row) => row[i]));

      setData((prevState) => transpose(prevState));
    }
  }, [isMobile]);
  return (
    <>
      <table className="table mb-5">
        <thead>
          <tr>
            {data[0].map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.slice(1).map((row, i) => (
            <tr key={i}>
              {row.map((cell, j) => (
                <td key={j}>
                  {typeof cell === "object" && cell.text === "PDF" ? (
                    cell.link === "#" ? (
                      <span>{cell.text}</span>
                    ) : (
                      <a
                        href={cell.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {cell.text}
                      </a>
                    )
                  ) : (
                    cell
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

// const ArticleCard = ({ title, link }) => (
//   <div className="col-md-3 col-sm-6 col-12">
//     <div className="card_container_historicos">
//       <div className="card_anio">
//         <h5>Artículo</h5>
//         <div className="card_trimestre">
//           <p>
//             {title}
//             <span className="badge bg-secondary">
//               <a href={link} target="_blank" rel="noreferrer">
//                 PDF
//               </a>
//             </span>
//           </p>
//         </div>
//       </div>
//     </div>
//   </div>
// );

export default ArmonizacionContable;
