import "./tabulador.css";
import Card from "../Card/Card";

function Tabulador() {
  return (
    <div className="container py-4">
      <div className="secretaria_funciones">
        <h3 className="title">TABULADOR</h3>
        <hr className="hr-gob" />
      </div>
      <div className="row">
      <Card
          document="Tabulador"
          href="/CAASIM/Tabulador/2025/Tabulador 2025.pdf"
          title="Archivo"
          year={2025}
        />
      <Card
          document="Tabulador"
          href="/CAASIM/Tabulador/2024/Tabulador 2024.pdf"
          title="Archivo"
          year={2024}
        />
        <Card
          document="Tabulador"
          href="/CAASIM/Tabulador/2023/Tabulador 2023.pdf"
          title="Archivo"
          year={2023}
        />
      </div>
    </div>
  );
}

export default Tabulador;