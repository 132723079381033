export const licitaciones2024 = [
  {
    titulo: "Convocatoria 01-2024",
    subtitulo: [
      {
        informacionTitulo: "EA-913005999-N1-2024",
      },
      {
        informacionTitulo: "EA-913005999-N2-2024",
      },
      {
        informacionTitulo: "EA-913005999-N3-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 02-2024",
    subtitulo: [
      {
        informacionTitulo: "EA-913005999-N4-2024",
      },
      {
        informacionTitulo: "EA-913005999-N5-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 03-2024",
    subtitulo: [
      {
        informacionTitulo: "EA-913005999-N6-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 01-2024 OBRA",
    subtitulo: [
      {
        informacionTitulo: "CAASIM-HGO-L.O.P.-01-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 04-2024",
    subtitulo: [
      {
        informacionTitulo: "EA-913005999-N7-2024",
      },
    ],
  },

  {
    titulo: "Convocatoria 05-2024",
    subtitulo: [
      {
        informacionTitulo: "EA-913005999-N8-2024",
      },
      {
        informacionTitulo: "EA-913005999-N9-2024",
      },
      {
        informacionTitulo: "EA-913005999-N10-2024",
      },
      {
        informacionTitulo: "EA-913005999-N11-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 06-2024",
    subtitulo: [
      {
        informacionTitulo: "EA-913005999-N12-2024",
      },
      {
        informacionTitulo: "EA-913005999-N13-2024",
      },
      {
        informacionTitulo: "EA-913005999-N14-2024",
      },
      {
        informacionTitulo: "EA-913005999-N15-2024",
      },
      {
        informacionTitulo: "EA-913005999-N16-2024",
      },
      {
        informacionTitulo: "EA-913005999-N17-2024",
      },
      {
        informacionTitulo: "EA-913005999-N18-2024",
      },
      {
        informacionTitulo: "EA-913005999-N19-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 07-2024",
    subtitulo: [
      {
        informacionTitulo: "EA-913005999-N20-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria Multiple 02-2024",
    subtitulo: [
      {
        informacionTitulo: "CAASIM-HGO-L.O.P.-02-2024",
      },
      {
        informacionTitulo: "CAASIM-HGO-L.O.P.-03-2024",
      },
      {
        informacionTitulo: "CAASIM-HGO-L.O.P.-04-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 03-2024 OBRA",
    subtitulo: [
      {
        informacionTitulo: "CAASIM-HGO-L.O.P.-05-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 08-2024",
    subtitulo: [
      {
        informacionTitulo: "EA-913005999-N22-2024",
      },
      {
        informacionTitulo: "EA-913005999-N24-2024",
      },
      {
        informacionTitulo: "EA-913005999-N25-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 09-2024",
    subtitulo: [
      {
        informacionTitulo: "EA-913005999-N21-2024",
      },
      {
        informacionTitulo: "EA-913005999-N26-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 10-2024",
    subtitulo: [
      {
        informacionTitulo: "EA-913005999-N27-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 04-2024 OBRA",
    subtitulo: [
      {
        informacionTitulo: "CAASIM-HGO-L.O.P.-06-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 11-2024",
    subtitulo: [
      {
        informacionTitulo: "EA-913005999-N28-2024",
      },
      {
        informacionTitulo: "EA-913005999-N29-2024",
      },
      {
        informacionTitulo: "EA-913005999-N30-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria Multiple 05-2024",
    subtitulo: [
      {
        informacionTitulo: "CAASIM-HGO-L.O.P.-07-2024",
      },
      {
        informacionTitulo: "CAASIM-HGO-L.O.P.-08-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 12-2024",
    subtitulo: [
      {
        informacionTitulo: "EA-913005999-N31-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 06-2024 OBRA",
    subtitulo: [
      {
        informacionTitulo: "CAASIM-HGO-L.O.P.-09-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 13-2024",
    subtitulo: [
      {
        informacionTitulo: "EA-913005999-N32-2024",
      },
      {
        informacionTitulo: "EA-913005999-N33-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 14-2024",
    subtitulo: [
      {
        informacionTitulo: "EA-913005999-N34-2024",
      },
      {
        informacionTitulo: "EA-913005999-N35-2024",
      },
      {
        informacionTitulo: "EA-913005999-N36-2024",
      },
      {
        informacionTitulo: "EA-913005999-N37-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 07-2024 OBRA",
    subtitulo: [
      {
        informacionTitulo: "CAASIM-HGO-L.O.P.-10-2024",
      },
      {
        informacionTitulo: "CAASIM-HGO-L.O.P.-11-2024",
      },
      {
        informacionTitulo: "CAASIM-HGO-L.O.P.-12-2024",
      },
      {
        informacionTitulo: "CAASIM-HGO-L.O.P.-13-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 15-2024",
    subtitulo: [
      {
        informacionTitulo: "EA-913005999-N38-2024",
      },
      {
        informacionTitulo: "EA-913005999-N39-2024",
      },
      {
        informacionTitulo: "EA-913005999-N40-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 16-2024",
    subtitulo: [
      {
        informacionTitulo: "EA-913005999-N41-2024",
      },
      {
        informacionTitulo: "EA-913005999-N42-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 17-2024",
    subtitulo: [
      {
        informacionTitulo: "EA-913005999-N43-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 08-2024 OBRA",
    subtitulo: [
      {
        informacionTitulo: "CAASIM-HGO-L.O.P.-14-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 18-2024",
    subtitulo: [
      {
        informacionTitulo: "EA-913005999-N44-2024",
      },
      {
        informacionTitulo: "EA-913005999-N45-2024",
      },
      {
        informacionTitulo: "EA-913005999-N46-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 19-2024",
    subtitulo: [
      {
        informacionTitulo: "EA-913005999-N47-2024",
      },
    ],
  },
  {
    titulo: "Convocatoria 20-2024",
    subtitulo: [
      {
        informacionTitulo: "EA-913005999-N48-2024",
      },
      {
        informacionTitulo: "EA-913005999-N49-2024",
      },
      {
        informacionTitulo: "EA-913005999-N50-2024",
      },
      {
        informacionTitulo: "EA-913005999-N51-2024",
      }
    ],
  },
  {
    titulo: "Convocatoria 21-2024",
    subtitulo: [
      {
        informacionTitulo: "EA-913005999-N52-2024",
      },
      {
        informacionTitulo: "EA-913005999-N53-2024",
      },
      {
        informacionTitulo: "EA-913005999-N54-2024",
      },
      {
        informacionTitulo: "EA-913005999-N55-2024",
      },
      {
        informacionTitulo: "EA-913005999-N56-2024",
      },
      {
        informacionTitulo: "EA-913005999-N57-2024",
      },
      {
        informacionTitulo: "EA-913005999-N58-2024",
      },
      {
        informacionTitulo: "EA-913005999-N59-2024",
      },
      {
        informacionTitulo: "EA-913005999-N60-2024",
      }
    ],
  },
  {
    titulo: "Convocatoria 22-2024",
    subtitulo: [
      // create for n61 to n80
      {
        informacionTitulo: "EA-913005999-N61-2024",
      },
      {
        informacionTitulo: "EA-913005999-N62-2024",
      },
      {
        informacionTitulo: "EA-913005999-N63-2024",
      },
      // {
      //   informacionTitulo: "EA-913005999-N64-2024",
      // },
      {
        informacionTitulo: "EA-913005999-N65-2024",
      },
      {
        informacionTitulo: "EA-913005999-N66-2024",
      },
      {
        informacionTitulo: "EA-913005999-N67-2024",
      },
      {
        informacionTitulo: "EA-913005999-N68-2024",
      },
      {
        informacionTitulo: "EA-913005999-N69-2024",
      },
      {
        informacionTitulo: "EA-913005999-N70-2024",
      },
      {
        informacionTitulo: "EA-913005999-N71-2024",
      },
      {
        informacionTitulo: "EA-913005999-N72-2024",
      },
      {
        informacionTitulo: "EA-913005999-N73-2024",
      },
      {
        informacionTitulo: "EA-913005999-N74-2024",
      },
      {
        informacionTitulo: "EA-913005999-N75-2024",
      },
      {
        informacionTitulo: "EA-913005999-N76-2024",
      },
      {
        informacionTitulo: "EA-913005999-N77-2024",
      },
      {
        informacionTitulo: "EA-913005999-N78-2024",
      },
      {
        informacionTitulo: "EA-913005999-N79-2024",
      },
      {
        informacionTitulo: "EA-913005999-N80-2024",
      },
    ],
  },
];
