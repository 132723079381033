import React from "react";
import "./mejoraRegulatoria.css";
import Card from "../Card/Card";

function MejoraRegulatoria() {
  const years = [2025, 2024, 2023, 2022, 2021];
  const yearLinks = years.map(year => ({
    year,
    href: `/CAASIM/mejora-regulatoria/Programa de Mejora Regulatoria CAASIM ${year}.pdf`,
  }));

  return (
    <div className="container py-4">
      <div className="secretaria_funciones">
        <h3 className="title">MEJORA REGULATORIA</h3>
        <hr className="hr-gob" />
      </div>
      <div className="row">
        {yearLinks.map(({ year, href }) => (
          <Card key={year} href={href} document={year} title="Información Anual" />
          // <CardAnio key={year} year={year} href={href} />
        ))}
      </div>
    </div>
  );
}

export default MejoraRegulatoria;
