export const tarifas = [
  {
    year: 2025,
    tarifas: [
      {
        month: "1 - Enero 2025",
        url: "/CAASIM/tarifas/2025/1 - Enero 2025.pdf",
      }
    ],
  },
  {
    year: 2024,
    tarifas: [
      {
        month: "1 - Enero 2024",
        url: "/CAASIM/tarifas/2024/1 - Enero 2024.pdf",
      },
      {
        month: "2 - Febrero 2024",
        url: "/CAASIM/tarifas/2024/2 - Febrero 2024.pdf",
      },
      {
        month: "3 - Marzo 2024",
        url: "/CAASIM/tarifas/2024/3 - Marzo 2024.pdf",
      },
      {
        month: "4 - Abril 2024",
        url: "/CAASIM/tarifas/2024/4 - Abril 2024.pdf",
      },
      {
        month: "5 - Mayo 2024",
        url: "/CAASIM/tarifas/2024/5 - Mayo 2024.pdf",
      },
      {
        month: "6 - Junio 2024",
        url: "/CAASIM/tarifas/2024/6 - Junio 2024.pdf",
      },
      {
        month: "7 - Julio 2024",
        url: "/CAASIM/tarifas/2024/7 - Julio 2024.pdf",
      },
      {
        month: "8 - Agosto 2024",
        url: "/CAASIM/tarifas/2024/8 - Agosto 2024.pdf",
      },
      {
        month: "9 - Septiembre 2024",
        url: "/CAASIM/tarifas/2024/9 - Septiembre 2024.pdf",
      },
      {
        month: "10 - Octubre 2024",
        url: "/CAASIM/tarifas/2024/10 - Octubre 2024.pdf",
      },
      {
        month: "11 - Noviembre 2024",
        url: "/CAASIM/tarifas/2024/11 - Noviembre 2024.pdf",
      },
      {
        month: "12 - Diciembre 2024",
        url: "/CAASIM/tarifas/2024/12 - Diciembre 2024.pdf",
      },
    ],
  },
  {
    year: 2023,
    tarifas: [
      {
        month: "1 - Enero 2023",
        url: "/CAASIM/tarifas/2023/1 - Enero 2023.pdf",
      },
      {
        month: "2 - Febrero 2023",
        url: "/CAASIM/tarifas/2023/2 - Febrero 2023.pdf",
      },
      {
        month: "3 - Marzo 2023",
        url: "/CAASIM/tarifas/2023/3 - Marzo 2023.pdf",
      },
      {
        month: "4 - Abril 2023",
        url: "/CAASIM/tarifas/2023/4 - Abril 2023.pdf",
      },
      { month: "5 - Mayo 2023", url: "/CAASIM/tarifas/2023/5 - Mayo 2023.pdf" },
      {
        month: "6 - Junio 2023",
        url: "/CAASIM/tarifas/2023/6 - Junio 2023.pdf",
      },
      {
        month: "7 - Julio 2023",
        url: "/CAASIM/tarifas/2023/7 - Julio 2023.pdf",
      },
      {
        month: "8 - Agosto 2023",
        url: "/CAASIM/tarifas/2023/8 - Agosto 2023.pdf",
      },
      {
        month: "9 - Septiembre 2023",
        url: "/CAASIM/tarifas/2023/9 - Septiembre 2023.pdf",
      },
      {
        month: "10 - Octubre 2023",
        url: "/CAASIM/tarifas/2023/10 - Octubre 2023.pdf",
      },
      {
        month: "11 - Noviembre 2023",
        url: "/CAASIM/tarifas/2023/11 - Noviembre 2023.pdf",
      },
      {
        month: "12 - Diciembre 2023",
        url: "/CAASIM/tarifas/2023/12 - Diciembre 2023.pdf",
      },
    ],
  },
  {
    year: 2022,
    tarifas: [
      {
        month: "1 - Enero 2022",
        url: "/CAASIM/tarifas/2022/1 - Enero 2022.pdf",
      },
      {
        month: "2 - Febrero 2022",
        url: "/CAASIM/tarifas/2022/2 - Febrero 2022.pdf",
      },
      {
        month: "3 - Marzo 2022",
        url: "/CAASIM/tarifas/2022/3 - Marzo 2022.pdf",
      },
      {
        month: "4 - Abril 2022",
        url: "/CAASIM/tarifas/2022/4 - Abril 2022.pdf",
      },
      { month: "5 - Mayo 2022", url: "/CAASIM/tarifas/2022/5 - Mayo 2022.pdf" },
      {
        month: "6 - Junio 2022",
        url: "/CAASIM/tarifas/2022/6 - Junio 2022.pdf",
      },
      {
        month: "7 - Julio 2022",
        url: "/CAASIM/tarifas/2022/7 - Julio 2022.pdf",
      },
      {
        month: "8 - Agosto 2022",
        url: "/CAASIM/tarifas/2022/8 - Agosto 2022.pdf",
      },
      {
        month: "9 - Septiembre 2022",
        url: "/CAASIM/tarifas/2022/9 - Septiembre 2022.pdf",
      },
      {
        month: "10 - Octubre 2022",
        url: "/CAASIM/tarifas/2022/10 - Octubre 2022.pdf",
      },
      {
        month: "11 - Noviembre 2022",
        url: "/CAASIM/tarifas/2022/11 - Noviembre 2022.pdf",
      },
      {
        month: "12 - Diciembre 2022",
        url: "/CAASIM/tarifas/2022/12 - Diciembre 2022.pdf",
      },
    ],
  },
  {
    year: 2021,
    tarifas: [
      {
        month: "1 - Enero 2021",
        url: "/CAASIM/tarifas/2021/1 - Enero 2021.pdf",
      },
      {
        month: "2 - Febrero 2021",
        url: "/CAASIM/tarifas/2021/2 - Febrero 2021.pdf",
      },
      {
        month: "3 - Marzo 2021",
        url: "/CAASIM/tarifas/2021/3 - Marzo 2021.pdf",
      },
      {
        month: "4 - Abril 2021",
        url: "/CAASIM/tarifas/2021/4 - Abril 2021.pdf",
      },
      { month: "5 - Mayo 2021", url: "/CAASIM/tarifas/2021/5 - Mayo 2021.pdf" },
      {
        month: "6 - Junio 2021",
        url: "/CAASIM/tarifas/2021/6 - Junio 2021.pdf",
      },
      {
        month: "7 - Julio 2021",
        url: "/CAASIM/tarifas/2021/7 - Julio 2021.pdf",
      },
      {
        month: "8 - Agosto 2021",
        url: "/CAASIM/tarifas/2021/8 - Agosto 2021.pdf",
      },
      {
        month: "9 - Septiembre 2021",
        url: "/CAASIM/tarifas/2021/9 - Septiembre 2021.pdf",
      },
      {
        month: "10 - Octubre 2021",
        url: "/CAASIM/tarifas/2021/10 - Octubre 2021.pdf",
      },
      {
        month: "11 - Noviembre 2021",
        url: "/CAASIM/tarifas/2021/11 - Noviembre 2021.pdf",
      },
      {
        month: "12 - Diciembre 2021",
        url: "/CAASIM/tarifas/2021/12 - Diciembre 2021.pdf",
      },
    ],
  },
  {
    year: 2020,
    tarifas: [
      {
        month: "1 - Enero 2020",
        url: "/CAASIM/tarifas/2020/1 - Enero 2020.pdf",
      },
      {
        month: "2 - Febrero 2020",
        url: "/CAASIM/tarifas/2020/2 - Febrero 2020.pdf",
      },
      {
        month: "3 - Marzo 2020",
        url: "/CAASIM/tarifas/2020/3 - Marzo 2020.pdf",
      },
      {
        month: "4 - Abril 2020",
        url: "/CAASIM/tarifas/2020/4 - Abril 2020.pdf",
      },
      { month: "5 - Mayo 2020", url: "/CAASIM/tarifas/2020/5 - Mayo 2020.pdf" },
      {
        month: "6 - Junio 2020",
        url: "/CAASIM/tarifas/2020/6 - Junio 2020.pdf",
      },
      {
        month: "7 - Julio 2020",
        url: "/CAASIM/tarifas/2020/7 - Julio 2020.pdf",
      },
      {
        month: "8 - Agosto 2020",
        url: "/CAASIM/tarifas/2020/8 - Agosto 2020.pdf",
      },
      {
        month: "9 - Septiembre 2020",
        url: "/CAASIM/tarifas/2020/9 - Septiembre 2020.pdf",
      },
      {
        month: "10 - Octubre 2020",
        url: "/CAASIM/tarifas/2020/10 - Octubre 2020.pdf",
      },
      {
        month: "11 - Noviembre 2020",
        url: "/CAASIM/tarifas/2020/11 - Noviembre 2020.pdf",
      },
      {
        month: "12 - Diciembre 2020",
        url: "/CAASIM/tarifas/2020/12 - Diciembre 2020.pdf",
      },
    ],
  },
  {
    year: 2019,
    tarifas: [
      {
        month: "11 - Noviembre 2019",
        url: "/CAASIM/tarifas/2019/11 - Noviembre 2019.pdf",
      },
      {
        month: "12 - Diciembre 2019",
        url: "/CAASIM/tarifas/2019/12 - Diciembre 2019.pdf",
      },
    ],
  },
];
