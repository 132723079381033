import {
  convocatoria001,
  convocatoria002,
  convocatoria003,
  n1,
  n2,
  n3,
  n4,
  n5,
  n6,
  convocatoriaLOP001,
  lop1,
  convocatoria004,
  n7,
  convocatoria005,
  n8,
  n9,
  n10,
  n11,
  convocatoria006,
  n12,
  n13,
  n14,
  n15,
  n16,
  n17,
  n18,
  n19,
  convocatoria007,
  n20,
  convocatoriaMultiple002,
  lop2,
  lop3,
  lop4,
  convocatoria03Obra,
  lop5,
  convocatoria008,
  n22,
  n24,
  n25,
  convocatoria009,
  n26,
  n21,
  convocatoria010,
  n27,
  convocatoria04Obra,
  lop6,
  convocatoria011,
  n28,
  n29,
  n30,
  convocatoriaMultiple005,
  lop7,
  lop8,
  convocatoria012,
  n31,
  convocatoria06Obra,
  lop9,
  convocatoria013,
  n32,
  n33,
  convocatoria014,
  n34,
  n35,
  n36,
  n37,
  convocatoria07Obra,
  lop10,
  lop11,
  lop12,
  lop13,
  convocatoria015,
  n38,
  n39,
  n40,
  convocatoria016,
  n41,
  n42,
  convocatoria017,
  n43,
  convocatoria08Obra,
  lop14,
  convocatoria018,
  n44,
  n45,
  n46,
  convocatoria019,
  n47,
  convocatoria020,
  n48,
  n49,
  n50,
  n51,
  convocatoria021,
  n52,
  n53,
  n54,
  n55,
  n56,
  n57,
  n58,
  n59,
  n60,
  convocatoria022,
  n61,
  n62,
  n63,
  n64,
  n65,
  n66,
  n67,
  n68,
  n69,
  n70,
  n71,
  n72,
  n73,
  n74,
  n75,
  n76,
  n77,
  n78,
  n79,
  n80,
} from "../../json/anioLicitaciones/2024/anioLicitacion2024";

export const obtenerDatosSeleccionados = (selectedItemIndex) => {
  let datosSeleccionados = [];

  switch (selectedItemIndex) {
    case "Convocatoria 01-2024":
      datosSeleccionados = convocatoria001;
      break;
    case "Convocatoria 02-2024":
      datosSeleccionados = convocatoria002;
      break;
    case "Convocatoria 03-2024":
      datosSeleccionados = convocatoria003;
      break;
    case "EA-913005999-N1-2024":
      datosSeleccionados = n1;
      break;
    case "EA-913005999-N2-2024":
      datosSeleccionados = n2;
      break;
    case "EA-913005999-N3-2024":
      datosSeleccionados = n3;
      break;
    case "EA-913005999-N4-2024":
      datosSeleccionados = n4;
      break;
    case "EA-913005999-N5-2024":
      datosSeleccionados = n5;
      break;
    case "EA-913005999-N6-2024":
      datosSeleccionados = n6;
      break;
    case "Convocatoria 01-2024 OBRA":
      datosSeleccionados = convocatoriaLOP001;
      break;
    case "CAASIM-HGO-L.O.P.-01-2024":
      datosSeleccionados = lop1;
      break;
    case "Convocatoria 04-2024":
      datosSeleccionados = convocatoria004;
      break;
    case "EA-913005999-N7-2024":
      datosSeleccionados = n7;
      break;
    case "Convocatoria 05-2024":
      datosSeleccionados = convocatoria005;
      break;
    case "EA-913005999-N8-2024":
      datosSeleccionados = n8;
      break;
    case "EA-913005999-N9-2024":
      datosSeleccionados = n9;
      break;
    case "EA-913005999-N10-2024":
      datosSeleccionados = n10;
      break;
    case "EA-913005999-N11-2024":
      datosSeleccionados = n11;
      break;
    case "Convocatoria 06-2024":
      datosSeleccionados = convocatoria006;
      break;
    case "EA-913005999-N12-2024":
      datosSeleccionados = n12;
      break;
    case "EA-913005999-N13-2024":
      datosSeleccionados = n13;
      break;
    case "EA-913005999-N14-2024":
      datosSeleccionados = n14;
      break;
    case "EA-913005999-N15-2024":
      datosSeleccionados = n15;
      break;
    case "EA-913005999-N16-2024":
      datosSeleccionados = n16;
      break;
    case "EA-913005999-N17-2024":
      datosSeleccionados = n17;
      break;
    case "EA-913005999-N18-2024":
      datosSeleccionados = n18;
      break;
    case "EA-913005999-N19-2024":
      datosSeleccionados = n19;
      break;
    case "Convocatoria 07-2024":
      datosSeleccionados = convocatoria007;
      break;
    case "EA-913005999-N20-2024":
      datosSeleccionados = n20;
      break;
    case "Convocatoria Multiple 02-2024":
      datosSeleccionados = convocatoriaMultiple002;
      break;
    case "CAASIM-HGO-L.O.P.-02-2024":
      datosSeleccionados = lop2;
      break;
    case "CAASIM-HGO-L.O.P.-03-2024":
      datosSeleccionados = lop3;
      break;
    case "CAASIM-HGO-L.O.P.-04-2024":
      datosSeleccionados = lop4;
      break;
    case "Convocatoria 03-2024 OBRA":
      datosSeleccionados = convocatoria03Obra;
      break;
    case "CAASIM-HGO-L.O.P.-05-2024":
      datosSeleccionados = lop5;
      break;
    case "Convocatoria 08-2024":
      datosSeleccionados = convocatoria008;
      break;
    case "EA-913005999-N22-2024":
      datosSeleccionados = n22;
      break;
    case "EA-913005999-N24-2024":
      datosSeleccionados = n24;
      break;
    case "EA-913005999-N25-2024":
      datosSeleccionados = n25;
      break;
    case "Convocatoria 09-2024":
      datosSeleccionados = convocatoria009;
      break;
    case "EA-913005999-N26-2024":
      datosSeleccionados = n26;
      break;
    case "EA-913005999-N21-2024":
      datosSeleccionados = n21;
      break;
    case "Convocatoria 10-2024":
      datosSeleccionados = convocatoria010;
      break;
    case "EA-913005999-N27-2024":
      datosSeleccionados = n27;
      break;
    case "Convocatoria 04-2024 OBRA":
      datosSeleccionados = convocatoria04Obra;
      break;
    case "CAASIM-HGO-L.O.P.-06-2024":
      datosSeleccionados = lop6;
      break;
    case "Convocatoria 11-2024":
      datosSeleccionados = convocatoria011;
      break;
    case "EA-913005999-N28-2024":
      datosSeleccionados = n28;
      break;
    case "EA-913005999-N29-2024":
      datosSeleccionados = n29;
      break;
    case "EA-913005999-N30-2024":
      datosSeleccionados = n30;
      break;
    case "Convocatoria Multiple 05-2024":
      datosSeleccionados = convocatoriaMultiple005;
      break;
    case "CAASIM-HGO-L.O.P.-07-2024":
      datosSeleccionados = lop7;
      break;
    case "CAASIM-HGO-L.O.P.-08-2024":
      datosSeleccionados = lop8;
      break;
    case "Convocatoria 12-2024":
      datosSeleccionados = convocatoria012;
      break;
    case "EA-913005999-N31-2024":
      datosSeleccionados = n31;
      break;
    case "Convocatoria 06-2024 OBRA":
      datosSeleccionados = convocatoria06Obra;
      break;
    case "CAASIM-HGO-L.O.P.-09-2024":
      datosSeleccionados = lop9;
      break;
    case "Convocatoria 13-2024":
      datosSeleccionados = convocatoria013;
      break;
    case "EA-913005999-N32-2024":
      datosSeleccionados = n32;
      break;
    case "EA-913005999-N33-2024":
      datosSeleccionados = n33;
      break;
    case "Convocatoria 14-2024":
      datosSeleccionados = convocatoria014;
      break;
    case "EA-913005999-N34-2024":
      datosSeleccionados = n34;
      break;
    case "EA-913005999-N35-2024":
      datosSeleccionados = n35;
      break;
    case "EA-913005999-N36-2024":
      datosSeleccionados = n36;
      break;
    case "EA-913005999-N37-2024":
      datosSeleccionados = n37;
      break;
    case "Convocatoria 07-2024 OBRA":
      datosSeleccionados = convocatoria07Obra;
      break;
    case "CAASIM-HGO-L.O.P.-10-2024":
      datosSeleccionados = lop10;
      break;
    case "CAASIM-HGO-L.O.P.-11-2024":
      datosSeleccionados = lop11;
      break;
    case "CAASIM-HGO-L.O.P.-12-2024":
      datosSeleccionados = lop12;
      break;
    case "CAASIM-HGO-L.O.P.-13-2024":
      datosSeleccionados = lop13;
      break;
    case "Convocatoria 15-2024":
      datosSeleccionados = convocatoria015;
      break;
    case "EA-913005999-N38-2024":
      datosSeleccionados = n38;
      break;
    case "EA-913005999-N39-2024":
      datosSeleccionados = n39;
      break;
    case "EA-913005999-N40-2024":
      datosSeleccionados = n40;
      break;
    case "Convocatoria 16-2024":
      datosSeleccionados = convocatoria016;
      break;
    case "EA-913005999-N41-2024":
      datosSeleccionados = n41;
      break;
    case "EA-913005999-N42-2024":
      datosSeleccionados = n42;
      break;
    case "Convocatoria 17-2024":
      datosSeleccionados = convocatoria017;
      break;
    case "EA-913005999-N43-2024":
      datosSeleccionados = n43;
      break;
    case "Convocatoria 08-2024 OBRA":
      datosSeleccionados = convocatoria08Obra;
      break;
    case "CAASIM-HGO-L.O.P.-14-2024":
      datosSeleccionados = lop14;
      break;
    case "Convocatoria 18-2024":
      datosSeleccionados = convocatoria018;
      break;
    case "EA-913005999-N44-2024":
      datosSeleccionados = n44;
      break;
    case "EA-913005999-N45-2024":
      datosSeleccionados = n45;
      break;
    case "EA-913005999-N46-2024":
      datosSeleccionados = n46;
      break;
    case "Convocatoria 19-2024":
      datosSeleccionados = convocatoria019;
      break;
    case "EA-913005999-N47-2024":
      datosSeleccionados = n47;
      break;
    case "Convocatoria 20-2024":
      datosSeleccionados = convocatoria020;
      break;
    case "EA-913005999-N48-2024":
      datosSeleccionados = n48;
      break;
    case "EA-913005999-N49-2024":
      datosSeleccionados = n49;
      break;
    case "EA-913005999-N50-2024":
      datosSeleccionados = n50;
      break;
    case "EA-913005999-N51-2024":
      datosSeleccionados = n51;
      break;
    case "Convocatoria 21-2024":
      datosSeleccionados = convocatoria021;
      break;
    case "EA-913005999-N52-2024":
      datosSeleccionados = n52;
      break;
    case "EA-913005999-N53-2024":
      datosSeleccionados = n53;
      break;
    case "EA-913005999-N54-2024":
      datosSeleccionados = n54;
      break;
    case "EA-913005999-N55-2024":
      datosSeleccionados = n55;
      break;
    case "EA-913005999-N56-2024":
      datosSeleccionados = n56;
      break;
    case "EA-913005999-N57-2024":
      datosSeleccionados = n57;
      break;
    case "EA-913005999-N58-2024":
      datosSeleccionados = n58;
      break;
    case "EA-913005999-N59-2024":
      datosSeleccionados = n59;
      break;
    case "EA-913005999-N60-2024":
      datosSeleccionados = n60;
      break;
    case "Convocatoria 22-2024":
      datosSeleccionados = convocatoria022;
      break;
    case "EA-913005999-N61-2024":
      datosSeleccionados = n61;
      break;
    case "EA-913005999-N62-2024":
      datosSeleccionados = n62;
      break;
    case "EA-913005999-N63-2024":
      datosSeleccionados = n63;
      break;
    // case "EA-913005999-N64-2024":
    //   datosSeleccionados = n64;
    //   break;
    case "EA-913005999-N65-2024":
      datosSeleccionados = n65;
      break;
    case "EA-913005999-N66-2024":
      datosSeleccionados = n66;
      break;
    case "EA-913005999-N67-2024":
      datosSeleccionados = n67;
      break;
    case "EA-913005999-N68-2024":
      datosSeleccionados = n68;
      break;
    case "EA-913005999-N69-2024":
      datosSeleccionados = n69;
      break;
    case "EA-913005999-N70-2024":
      datosSeleccionados = n70;
      break;
    case "EA-913005999-N71-2024":
      datosSeleccionados = n71;
      break;
    case "EA-913005999-N72-2024":
      datosSeleccionados = n72;
      break;
    case "EA-913005999-N73-2024":
      datosSeleccionados = n73;
      break;
    case "EA-913005999-N74-2024":
      datosSeleccionados = n74;
      break;
    case "EA-913005999-N75-2024":
      datosSeleccionados = n75;
      break;
    case "EA-913005999-N76-2024":
      datosSeleccionados = n76;
      break;
    case "EA-913005999-N77-2024":
      datosSeleccionados = n77;
      break;
    case "EA-913005999-N78-2024":
      datosSeleccionados = n78;
      break;
    case "EA-913005999-N79-2024":
      datosSeleccionados = n79;
      break;
    case "EA-913005999-N80-2024":
      datosSeleccionados = n80;
      break;
    default:
      datosSeleccionados = [];
  }

  return datosSeleccionados;
};
